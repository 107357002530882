<template>
  <div class="clouds-context-menu">
    <template v-if="menu.length && menu[0].length">
      <div v-for="(group, i) in menu" :key="i" class="clouds-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="clouds-context-menu__item standart-title"
          :class="{ [`clouds-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{
            item.tool.type === 'group' && (isProcessing || processingStatus === '1')
              ? $t(`actions.process`)
              : $t(`actions.${item.key}`)
          }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="clouds-context-menu__noact standart-text">
        <div v-if="isSuspended" class="standart-title">
          {{ $t('noact.suspended') }}
        </div>
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { CloudTariff } from '@/models/BillMgr/CloudTariff';
import { BillMgrTool } from '@/models/BillMgr/Tools';
import serialize from '@/utils/serialize';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
export default {
  name: 'CloudsContextMenu',
  props: {
    tariff: {
      type: CloudTariff,
      required: true,
      validator: obj => obj instanceof CloudTariff,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      oldState: this.tariff.hourly_can,
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {
    tools() {
      return this.$store.state.moduleClouds.tools;
    },
    toolsMenu() {
      const { start, stop, gotoserver } = this.tools;
      return gotoserver ? [start, stop, gotoserver] : [start, stop];
    },
    processingStatus() {
      return this.tariff.specialstatus.toNumber();
    },
    isSuspended() {
      return this.tariff.abusesuspend === 'on' || this.tariff.employeesuspend === 'on';
    },
    menu() {
      const filteredMap = this.toolsMenu
        .filter(tool => tool.isEnable(this.tariff) || tool.name === this.oldState)
        .map(tool => {
          return {
            key: tool.name,
            tool,
            disabled:
              (tool.type === 'group' && (this.isProcessing || this.processingStatus === 1)) ||
              !tool.isEnable(this.tariff),
          };
        });
      return [filteredMap];
    },
  },
  mounted() {
    if (this.processingStatus === 1) this.updateStatus();
  },
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.handler && item.handler === 'func') this.askSure(item.key);
      else if (item.tool && item.tool instanceof BillMgrTool) {
        try {
          this.handleBillMgrTool(item);
        } catch (e) {
          console.error(e);
        }
      } else if (!item.attrs) alert(`id: ${this.tariff.id}, \r\naction: ${item.title}`);
    },
    askSure(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let time;
      this.$modals.open({
        name: selfName,
        text: this.$t('modal.sure.text', { msg: this.$t(`actions.${action}`) }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                this.oldState = action !== 'restart' ? this.serverState.toString() : 'processing';
                time = Date.now();
                this.isProcessing = true;
                this.sendServerAction(action)
                  .then(() => {
                    res = 'success';
                    this.updateServerInfo();
                  })
                  .catch(() => (res = 'fail'))
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      self.text = this.$t(`modal.sure.${res}`);
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: { click: () => this.$modals.close() },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') this.goToWindow(item);
      else if (item.tool.type === 'group') this.sendGroupTool(item);
      else throw new Error(`Handler for tool type ${item.tool.type} is not isset`);
    },
    sendGroupTool(item) {
      this.isProcessing = true;
      this.oldState = this.tariff.hourly_can;
      this.$store
        .dispatch('moduleClouds/fetchBillMgrToolAction', {
          func: item.tool.func,
          elid: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok) this.updateStatus();
        });
    },
    goToWindow(item) {
      let modal = null;
      this.$modals.open({
        name: 'ToPleskPanel',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.$store
        .dispatch('moduleClouds/fetchBillMgrToolAction', {
          func: item.tool.func,
          elid: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let payload = item.payload ? serialize(item.payload) : '';
            this.$modals.close();
            window.open(data.ok.v + payload);
          }
        });
    },
    updateStatus() {
      this.timerId = setTimeout(() => {
        this.$store.dispatch('moduleClouds/updateList', { silent: true }).then(() => {
          if (this.processingStatus === 1) {
            if (this.timerCnt < 5) this.timerCnt += 1;
            this.updateStatus();
          } else {
            this.timerCnt = 0;
            this.isProcessing = false;
            this.oldState = '';
            clearTimeout(this.timerId);
          }
        });
      }, 1000 * this.timerCnt);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "actions": {
      "change": "изменить",
      "stop": "остановить",
      "gotoserver": "перейти в панель",
      "history": "история",
      "start": "запустить",
      "process": "обновляем...",
      "restart": "перезагрузить",
      "createBackup": "создать бэкап",
      "recreateServer": "пересоздать сервер",
      "removeServer": "удалить сервер"
    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.clouds-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.clouds-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
